import React, { FC } from 'react';
import dinner_black from '../assets/images/dinner_black.png';
import line from '../assets/images/line.png';
import './style.css';

const Logo: FC<any> = (props) => {
  const infoLineClass = props.mutedText ? 'd-none' : '';
  const infoTextClass = props.mutedText ? 'text-muted small text-wrap' : 'h6 font-weight-bold';

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="mt-5">
        <img src={dinner_black} className="black_logo" alt="" />
      </div>
      <div className="mt-3 mb-3">
        <span className="h1">Restaurant paneel</span>
      </div>
      <div className="mt-1 mb-3">
        <div className="d-flex flex-row">
          <div>
            <img src={line} className={`${infoLineClass}`} alt="" />
          </div>
          {'\u00A0'}
          <div>
            <span className={infoTextClass}>{props.infoText}</span>
          </div>
          {'\u00A0'}
          <div>
            <img src={line} className={`${infoLineClass}`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
