import { AxiosPromise, AxiosResponse } from 'axios';

import api, { CreateServiceUrl } from './api';

const u = CreateServiceUrl('SiteApi');

const login = (data: FormData): Promise<AxiosResponse> => {
  return api.post(u`login`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const register = (data: FormData) => {
  return api.post(u`register`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const resetPassword = (user: string, key: string, data: FormData): Promise<AxiosResponse> => {
  const url = `${user}/forgotpasswordconfirm/${key}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const forgotPassword = (data: FormData): Promise<AxiosResponse> => {
  return api.post(u`forgotpassword/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getRestaurantByZipCode = (data: FormData) => {
  return api.post(u`fetchrestaurantbyzipcode`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

interface Response<T> {
  success: boolean;
  message: string;
  data: T;
}

function getAllRestaurants<T>(): Promise<Response<T>> {
  return api.get(u`fetchallrestaurant`);
}

const getOrderDetails = (orderId: string): Promise<AxiosResponse> => {
  return api.get(u`orderdetails/${orderId}`);
};

const getArticleRestaurant = (id: string) => {
  return api.get(u`articlesperrestaurant/${id}`);
};

const customerMSQ = (data: FormData) => {
  return api.post(u`customermsg`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export {
  login,
  getAllRestaurants,
  resetPassword,
  forgotPassword,
  getOrderDetails,
  register,
  getRestaurantByZipCode,
  getArticleRestaurant,
  customerMSQ,
};
