import axios from 'axios';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import dinner_black from '../assets/images/dinner_black.png';
import line from '../assets/images/line.png';
import { API_BASE_URL } from '../services/urls';
import './style.css';

let ps: any;

const Sidebar: FC<any> = (props) => {
  const [openOptions, setOpenOptions] = useState(false);
  const rest_id = localStorage.getItem('rest_id');
  const sidebar = useRef<any>();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  const closeSidebar = () => {
    document.documentElement.classList.remove('nav-open');
  };

  const logout = () => {
    localStorage.removeItem('rest_id');
    localStorage.removeItem('jwt');
    // alert(localStorage.getItem("rest_id"));

    axios
      .get(`${API_BASE_URL}/restaurant/api/logout/`)
      .catch((err) => console.log('Error is', err.message));
  };

  const onClickArticleOptions = () => {
    closeSidebar();
    setOpenOptions(!openOptions);
  };

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo d-none d-sm-none d-md-block d-lg-block">
        <NavLink to="/admin/dashboard" className="simple-text logo-normal">
          <img src={dinner_black} className="headerLogo" alt="" />
          {'\u00A0'}
          Restaurant paneel
        </NavLink>
      </div>
      <div className="sidebar-wrapper mt-5" ref={sidebar}>
        <Nav>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/dashboard"
              className="nav-link"
              activeClassName="active"
              id="dashboard_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Bewerk restaurant</span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to={`/admin/qrdonwload/${rest_id}`}
              className="nav-link"
              activeClassName="active"
              id="restaurant_qrdownload"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Maak unieke qr codes aan</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/foodCategory"
              className="nav-link"
              activeClassName="active"
              id="foodcategories_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Categoriën</span>
            </NavLink>
          </li>
          <li onClick={onClickArticleOptions}>
            <NavLink
              to="/admin/articleoptionsName"
              className="nav-link"
              activeClassName="active"
              id="articleoptiongroup_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Productoptie groepen</span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/articleoptions"
              className="nav-link"
              activeClassName="active"
              id="articleoption_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Beheer productopties</span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/editArticle"
              className="nav-link"
              activeClassName="active"
              id="editarticle_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Beheer producten</span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/csvUpload"
              className="nav-link"
              activeClassName="active"
              id="csv_colorchange"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">CSV Upload</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/Orders"
              className="nav-link"
              activeClassName="active"
              id="orders_colorchange"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Bestellingen</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/printers"
              className="nav-link"
              activeClassName="active"
              id="printers_colorchange"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Printerinstellingen</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/changePassword"
              className="nav-link"
              activeClassName="active"
              id="changePassword"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Pas wachtwoord aan</span>
            </NavLink>
          </li>
          <li onClick={logout}>
            <NavLink
              to="/auth/login"
              className="nav-link"
              activeClassName="active"
              id="logout_colorchange"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Logout</span>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  );
};
export default Sidebar;
