import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getArticleOptions } from '../store/actions';

const ArticleOptionsgroupModal: FC<any> = (props: any) => {
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState('');

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    setArticleOptionid(props.articleoptId);
    if (typeof props.articleopt === 'undefined') {
      setArticleOption('');
    } else {
      setArticleOption(props.articleopt);
      console.log(props.articleopt);
    }
  }, [props]);

  const changeOptionNamedata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const optionname = e.target.elements.optionname.value;
    const choice = e.target.elements.choice.value;
    const choice_price = e.target.elements.choice_price.value;

    const restid = localStorage.getItem('rest_id') as string;
    if (articleOptionid === 'new') {
      await API.restaurantApi
        .updateNewArticleOption(
          {
            optionname,
            articleoptionnamerestaurant: restid,
          },
          restid
        )
        .then((response) => {
          toast.success('Added Successfully!', { containerId: 'B' });
          asyncDispatch(getArticleOptions(id)).then(() => props.toggle());
        });
    } else {
      await API.restaurantApi
        .updateArticleOption(
          {
            articleoptionrestaurant: restid,
            optionname,
            choice,
            choice_price,
          },
          restid,
          articleOptionid
        )
        .then((response) => {
          toast.success('Updated Successfully!', { containerId: 'B' });
          asyncDispatch(getArticleOptions(id)).then(() => props.toggle());
        });
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit Article Option</ModalHeader>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    Choice
                  </label>
                  <InputField
                    name="choice"
                    placeholder="Enter Choice"
                    value={articleOption.choice}
                    onChange={(e: any) => changeOptionNamedata(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    Prijs
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id=""
                    placeholder="Enter Prijs"
                    name="choice_price"
                    value={articleOption.choice_price}
                    onChange={(e) => changeOptionNamedata(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small" />
                  <InputField
                    type="hidden"
                    name="optionname"
                    placeholder="Enter Name"
                    value={articleOption.optionname ? articleOption.optionname.optionname : ''}
                    onChange={(e: any) => changeOptionNamedata(e)}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button name="Cancel" btnLongWidth={false} onClick={props.toggle} type="button" />

            <Button name="Submit" dangerButton={true} type="submit" btnLongWidth={false} />
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
export default ArticleOptionsgroupModal;
