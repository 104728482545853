import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ArticleOptionsModal, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getArticleOptionNames } from '../../store/actions';

let ps: any;

const ArticleOptionsName: FC<any> = (props) => {
  const [modal, setModal] = useState(false);
  const [articleoptNameId, setArticleoptNameId] = useState(0);
  const [ArticleoptNameobj, setArticleoptNameobj] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const articleOptionsNames = useSelector(
    ({ articleOptionNames }: StoreRoot) => articleOptionNames
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Option Group';
    // @ts-ignore
    document.getElementById('articleoptiongroup_colorchange').style.color = 'darkred';
    dispatch(getArticleOptionNames(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleoptNameId(id);
    setArticleoptNameobj(articleopt);
  };

  const deleteoptionnama = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteArticleOptionName(restid, id)
      .then((response) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getArticleOptionNames(restid));
      })
      .catch((error) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getArticleOptionNames(restid));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {articleOptionsNames && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Productoptie groepen</span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-danger rounded-0 shadow-none"
              >
                Voeg groep toe
              </button>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {/* <th className='small'>Article ID</th> */}
                      <th className="small">Naam optie</th>
                      <th className="small">Add Option Names</th>
                      <th className="small">Active Option Name</th>
                      <th className="small">Available Option Names</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptionsNames.map((articleopt: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {/* <td>{articleopt.id}</td> */}
                            <td>{articleopt.optionname}</td>
                            <td>
                              <a href={`optiongroups/${articleopt.id}`}>
                                <button
                                  type="button"
                                  style={{
                                    background: '#8b0000',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.375rem 0.75rem',
                                  }}
                                >
                                  {' '}
                                  Create Article Options
                                </button>
                              </a>
                            </td>
                            <td>{articleopt.activetab ? articleopt.activetab.alias : ''}</td>

                            <td>
                              {articleopt.optionofarticle
                                ? articleopt.optionofarticle.map((option: any, index: number) => (
                                    <div key={index}>
                                      <Link to={`/admin/articleoption/${option.id}`}>
                                        {`${option.label},`}
                                      </Link>
                                    </div>
                                  ))
                                : ''}
                            </td>

                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={articleopt.id}
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => deleteoptionnama(articleopt.id)}
                                id={articleopt.id}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <ArticleOptionsModal
        articleopt={ArticleoptNameobj}
        modal={modal}
        toggle={toggle}
        articleoptId={articleoptNameId}
      />
    </>
  );
};
export default ArticleOptionsName;
