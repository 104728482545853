import QRCode from 'qrcode.react';
import React, { FC, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const DownloadQR: FC = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const downloadQR = () => {
    const canvas = document.getElementById('123456') as any;
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = '12345.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div className="text-center mt-5">
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="text-center">
            <QRCode
              id="123456"
              value="tousif.noor@outlook.com"
              size={290}
              level="H"
              includeMargin={true}
            />
            <span className="cursor" onClick={downloadQR}>
              {' '}
              Download QR{' '}
            </span>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DownloadQR;
